@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.name-div p{
    margin: 0;
}
 .name-div h2{
     text-align: center;
 }

.name-div{
    display: inline-block;
    margin: 2rem 0;
    width: 100%;
}
  
#profilepic{
    transition: 1s;
    width: 100%;
    max-width: 15rem;
    border-radius: 15rem;
    display: block;
    margin: 1rem auto;
    /* box-shadow: inset rgba(20,20,20,0.7) 0 0 1rem 1rem; */
}



@media (max-width: 767px)  {
    #profilepic{
      visibility: hidden;
      height: 0;
    }
  }
.icon{
    height: 3rem;
    margin: 0 1rem;
}

.extLink{
    display: inline;
    text-align: center;
}

.cent {
    text-align: center;
}


.jb-body{
    background-image: url(/static/media/dots.6da1ac74.svg);
    background-attachment: fixed;
    padding: 2rem 0;
    min-height: 100vh;
    color: white;
}

.no-marg {
    margin: 0;
}

ul {
    padding: 1rem;
}
.sc-body{
    background-image: url(/static/media/dots.6da1ac74.svg);
    background-attachment: fixed;
    padding: 2rem 0;
    min-height: 100vh;
    color: white;
}

.vcenter {
    display: flex;
    align-items: center;
}
#mainApp{
  /* background-image: url(./img/lots_dots.svg); */
  background-attachment: fixed;
}

h1, 
h2,
h3,
h4, 
h5 {
  font-family: 'IBM Plex Sans', sans-serif;
}

p {
  font-size: 1.25rem;
}

a{
  color: #CCC;
}

.inline-text h1, 
.inline-text p {
  display: inline;
}

.cont{
  background-color: rgba(20,20,20,0.7);
  padding: 1rem ;
  color: #FFF;
  border-radius: 1rem;
}

.cont > h1, .cont > h5{
  text-align: center;
}

.vcenter {
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.sectpad{
  margin: 15rem 0;
}

@media (min-width: 768px)  {
  .desk-padding{
    padding: 3rem 0;
  }
 
  h2{
    font-size: 3rem;
  }

  h1, 
  h4 {
    font-size: 2rem;
  }
}

@media (max-width: 767px)  {
  .desk-padding{
    padding: 2rem 0 0 0;
  }

  h1, 
  h2,
  h3,
  h4 {
    font-size: 1.5rem;
  }
}



#bgdsvg{
    position: fixed;
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
}

.trans{
    transition: background-color 1s
}

.trans>path {
    transition: 1s;
}

.bg1 {
    background-color: #79ecf2;
}

.bg1>path {
    fill: #66c7cc;
}

.bg2{
    background-color: #79f279;
} 

.bg2>path {
    fill: #66cc66;
}

.bg3{
    background-color: #f2f279;
}

.bg3>path{
    fill: #cccc66;
}

.bg4{
    background-color: #f2ac79;
}

.bg4>path{
    fill: #cc9166;
}
.bgsvg{
    background-image: url(/static/media/lots_dots.30384695.svg);
    width: 100vw;
    height: 100vh;
    position: fixed;
}
