.jb-body{
    background-image: url('../img/dots.svg');
    background-attachment: fixed;
    padding: 2rem 0;
    min-height: 100vh;
    color: white;
}

.no-marg {
    margin: 0;
}

ul {
    padding: 1rem;
}