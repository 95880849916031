.sc-body{
    background-image: url('../img/dots.svg');
    background-attachment: fixed;
    padding: 2rem 0;
    min-height: 100vh;
    color: white;
}

.vcenter {
    display: flex;
    align-items: center;
}