#bgdsvg{
    position: fixed;
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
}

.trans{
    transition: background-color 1s
}

.trans>path {
    transition: 1s;
}

.bg1 {
    background-color: #79ecf2;
}

.bg1>path {
    fill: #66c7cc;
}

.bg2{
    background-color: #79f279;
} 

.bg2>path {
    fill: #66cc66;
}

.bg3{
    background-color: #f2f279;
}

.bg3>path{
    fill: #cccc66;
}

.bg4{
    background-color: #f2ac79;
}

.bg4>path{
    fill: #cc9166;
}