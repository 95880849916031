.icon{
    height: 3rem;
    margin: 0 1rem;
}

.extLink{
    display: inline;
    text-align: center;
}

.cent {
    text-align: center;
}

