@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap');

#mainApp{
  /* background-image: url(./img/lots_dots.svg); */
  background-attachment: fixed;
}

h1, 
h2,
h3,
h4, 
h5 {
  font-family: 'IBM Plex Sans', sans-serif;
}

p {
  font-size: 1.25rem;
}

a{
  color: #CCC;
}

.inline-text h1, 
.inline-text p {
  display: inline;
}

.cont{
  background-color: rgba(20,20,20,0.7);
  padding: 1rem ;
  color: #FFF;
  border-radius: 1rem;
}

.cont > h1, .cont > h5{
  text-align: center;
}

.vcenter {
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.sectpad{
  margin: 15rem 0;
}

@media (min-width: 768px)  {
  .desk-padding{
    padding: 3rem 0;
  }
 
  h2{
    font-size: 3rem;
  }

  h1, 
  h4 {
    font-size: 2rem;
  }
}

@media (max-width: 767px)  {
  .desk-padding{
    padding: 2rem 0 0 0;
  }

  h1, 
  h2,
  h3,
  h4 {
    font-size: 1.5rem;
  }
}


